import { Decimal } from 'decimal.js';
import { unionBy as _unionBy } from 'lodash';

const getAmortization = (amortizationItem, isPredicted) => {
  return isPredicted
    ? amortizationItem.amortizationPredicted ?? []
    : amortizationItem.amortizationActual ?? [];
};

export const transformAmortizationData = ({ data, isPredicted }) => {
  data.sort((a, b) => {
    return new Date(a.transferDate) - new Date(b.transferDate);
  });
  let newData = [];
  let dates = data.map((item) =>
    getAmortization(item, isPredicted).map((a) => a.date)
  );

  dates = _unionBy(dates.flat(), (date) => date).sort();
  let totals = {
    amountSold: new Decimal(0),
    purchasePrice: new Decimal(0),
    amortization: dates.map((date) => {
      return {
        date,
        amountToPay: new Decimal(0),
        actualPaidAmount: new Decimal(0),
        leftToPay: new Decimal(0),
      };
    }),
  };

  for (const item of data) {
    const amountSold = isPredicted
      ? item.amountSoldPredicted
      : item.amountSoldActual;

    let row = {
      transferDate: item.transferDate,
      amountSold,
      purchasePrice: item.purchasePrice,
      dealType: item.dealType,
      cohortStartDate: item.cohortStartDate,
      cohortEndDate: item.cohortEndDate,
      fee: item.fee,
      revenueType: item.revenueType,
      revenueSharePct: item.revenueSharePct,
      revenueMarginPct: item.revenueMarginPct,
    };

    totals.amountSold = totals.amountSold.plus(amountSold);
    totals.purchasePrice = totals.purchasePrice.plus(item.purchasePrice);

    const amortizationAmounts = dates.map((date, index) => {
      const amortization = getAmortization(item, isPredicted).find(
        (a) => a.date === date
      );
      if (!!amortization) {
        totals.amortization[index].amountToPay = totals.amortization[
          index
        ].amountToPay.plus(
          amortization.amountToPay === null
            ? amortization.minAmount
            : amortization.amountToPay
        );

        totals.amortization[index].actualPaidAmount = totals.amortization[
          index
        ].actualPaidAmount.plus(
          amortization.actualPaidAmount === null
            ? 0
            : amortization.actualPaidAmount
        );

        totals.amortization[index].leftToPay = totals.amortization[
          index
        ].amountToPay.minus(totals.amortization[index].actualPaidAmount);

        return amortization;
      }

      return null;
    });

    row.amortization = amortizationAmounts;
    newData.push(row);
  }

  return {
    dates,
    data: newData,
    totals,
  };
};
