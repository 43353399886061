import { useEffect, useState } from 'react';

import { DownloadIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  InputGroup,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  downloadInstallmentsReportApi,
  getCustomersDealsOverviewApi,
  updateCascadeAssetTapeReportApi,
} from 'api/dealApi';
import DataTable from 'components/DataTable';
import { Currency } from 'components/Number';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { REVENUE_RECOGNITION_TYPE } from 'constants/dealConstants';
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import OperationsKpis from './OperationsKpis';
import RevenueRecognitionSelect from './RevenueRecognitionSelect';

const columnHelper = createColumnHelper();

const getCurrencyCellValue = (info) => {
  const value = info.getValue();
  return value === null ? (
    'N/A'
  ) : (
    <Currency value={value} maximumFractionDigits={2} />
  );
};

const columns = [
  columnHelper.accessor('companyName', {
    cell: (info) => info.getValue(),
    header: 'Customer',
  }),
  columnHelper.accessor('totalPurchase', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Purchase Price',
  }),
  columnHelper.accessor('totalSold', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Amount Sold',
  }),
  columnHelper.accessor('amountDueOutstanding', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Amount Due Outstanding',
  }),
  columnHelper.accessor('principalDueOutstanding', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Principal Due Outstanding',
  }),
  columnHelper.accessor('feeDueOutstanding', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Fee Due Outstanding',
  }),
  columnHelper.accessor('principalRepaid', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Principal Repaid',
  }),
  columnHelper.accessor('feeRepaid', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Fee Repaid',
  }),
  columnHelper.accessor('nextRepaymentDate', {
    cell: (info) =>
      info.getValue() === null
        ? 'N/A'
        : moment(info.getValue()).format('MMM DD, YYYY'),
    header: 'Next Repayment Date',
  }),
];

const OperationsOverview = () => {
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [syncReportLoading, setSyncReportLoading] = useState(false);
  const [revenueRecognition, setRevenueRecognition] = useState(
    REVENUE_RECOGNITION_TYPE.INTEREST_FIRST
  );

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getCustomersDealsOverviewApi({ revenueRecognition });
        setData(data);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to get operation overview data',
          status: 'error',
        });
        throw error;
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [revenueRecognition]);

  const updateCascadeReport = async () => {
    try {
      setSyncReportLoading(true);
      await updateCascadeAssetTapeReportApi();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update cascade report',
        status: 'error',
      });
      throw error;
    } finally {
      setSyncReportLoading(false);
    }
  };

  const filterCustomers = (_customers) => {
    if (!searchValue) {
      return _customers;
    }

    return _customers.filter((customer) => {
      return (
        customer.companyName
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      );
    });
  };

  const downloadInstallmentsReport = async () => {
    try {
      await downloadInstallmentsReportApi({
        fileName: 'installments_report',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to download installments report',
        status: 'error',
      });
      throw error;
    }
  };

  const navigateToInstallment = (customerId) => {
    navigate(`/operations/${customerId}/installment`);
  };

  if (loading && !data) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box maxW={'container.xl'}>
      <OverlaySpinnerWrapper show={loading}>
        <Flex justifyContent={'space-between'} align={'strech'}>
          <OperationsKpis {...data?.kpis} />
          <Flex
            alignItems={'end'}
            direction={'column'}
            gap={4}
            justify={'space-between'}
          >
            <RevenueRecognitionSelect
              value={revenueRecognition}
              onChange={setRevenueRecognition}
            />
            <Flex direction={'column'} align={'end'} gap={1}>
              <Button
                w={225}
                variant={'brand'}
                onClick={updateCascadeReport}
                isLoading={syncReportLoading}
                loadingText={'Generating...'}
              >
                Create Cascade Report
              </Button>
              <Button
                as={NavLink}
                variant={'link'}
                target={'_blank'}
                to={process.env.REACT_APP_CASCADE_TEARSHEET_URL}
              >
                Cascade Report &gt;
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify={'end'} mt={5}>
          <Button
            variant={'outline'}
            leftIcon={<DownloadIcon />}
            onClick={downloadInstallmentsReport}
          >
            Download Installments Report
          </Button>
        </Flex>
        <Card mt={5}>
          <InputGroup mb={6} justifyContent={'space-between'}>
            {' '}
            <SearchBar
              h="44px"
              onChange={(event) => setSearchValue(event.target.value)}
              w={{ lg: '390px' }}
              placeholder="Search by Company Name"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  const customers = filterCustomers(data.customers);
                  if (customers.length > 0) {
                    navigateToInstallment(customers[0].id);
                  }
                }
              }}
            />
          </InputGroup>
          <Box minH={300} mx={-5}>
            <DataTable
              columns={columns}
              data={filterCustomers(data?.customers || [])}
              hasPagination={true}
              defaultSorting={[{ id: 'totalSold', desc: true }]}
              onRowClick={(row) => {
                navigateToInstallment(row.id);
              }}
              rowHeight={'100px'}
            />
          </Box>
        </Card>
      </OverlaySpinnerWrapper>
    </Box>
  );
};

OperationsOverview.propTypes = {};

export default OperationsOverview;
