import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { Currency, Percentage } from 'components/Number';
import PropTypes from 'prop-types';

const TotalFeeSummary = ({ totalFeeAmount, totalFeePct }) => {
  return (
    <SimpleGrid columns={2} spacing={2} fontSize={'md'}>
      <Text>Total Fee</Text>
      <Box>
        <Currency value={totalFeeAmount} />
      </Box>
      <Text>Final Fee</Text>
      <Box>
        <Percentage value={totalFeePct} />
      </Box>
    </SimpleGrid>
  );
};

TotalFeeSummary.propTypes = {
  totalFeeAmount: PropTypes.number,
  totalFeePct: PropTypes.number,
};

export default TotalFeeSummary;
