import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createDealApi, getDealApi } from 'api/dealApi';
import { PercentageNumberInput, TwNumberInput } from 'components/NumberInput';
import Card from 'components/card/Card';
import SelectInput from 'components/inputs/SelectInput';
import BackLink from 'components/links/BackLink';
import {
  CUSTOMER_SEGMENT_TYPE_META,
  DEAL_TYPE,
  DEAL_TYPE_META,
  FEE_TYPE,
  REPAYMENT_FREQUENCY_TYPE_META,
  TOTAL_TYPE_META,
} from 'constants/dealConstants';
import { renderInputElement } from 'helpers/formHelper';
import { generateSelectOptions } from 'helpers/inputHelper';
import { hasObjectAllValues, isObjectEmpty } from 'helpers/objectHelper';
import { useFeeTypeOptions } from 'hooks/useFeeTypeOptions';
import cloneDeep from 'lodash.clonedeep';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRepaymentSchedule } from './hooks/useRepaymentSchedule';

const createFields = ({
  dealType,
  formValues,
  repaymentDaysOptions,
  daysBeforeEndOfMonthOptions,
  feeTypeOptions,
}) => {
  let fields = [
    {
      label: 'DealType',
      name: 'dealType',
      element: SelectInput,
      placeholder: 'Select Deal Type',
      options: generateSelectOptions({ metaObj: DEAL_TYPE_META }),
    },
    {
      label: 'Deal Name',
      name: 'name',
      element: Input,
    },
    {
      label: 'Payment Frequency',
      name: 'repaymentFrequency',
      element: SelectInput,
      placeholder: 'Select Payment Frequency',
      options: generateSelectOptions({
        metaObj: REPAYMENT_FREQUENCY_TYPE_META,
      }),
      formatValue: (value) => {
        return REPAYMENT_FREQUENCY_TYPE_META[value]?.label;
      },
    },
    {
      label: 'Repayment Days',
      name: 'repaymentDays',
      element: SelectInput,
      placeholder: 'Select Repayment Days',
      options: repaymentDaysOptions,
      isMulti: true,
      isDisabled: !formValues.repaymentFrequency,
    },
    {
      label: 'Days Before End of Month',
      name: 'daysBeforeEndOfMonth',
      element: SelectInput,
      required: true,
      isNumber: true,
      options: daysBeforeEndOfMonthOptions,
      isHidden: !formValues?.repaymentDays?.includes(-1),
    },
    {
      label: 'Customer Segment',
      name: 'customerSegment',
      element: SelectInput,
      placeholder: 'Select Customer Segment',
      options: generateSelectOptions({
        metaObj: CUSTOMER_SEGMENT_TYPE_META,
      }),
      formatValue: (value) => {
        return CUSTOMER_SEGMENT_TYPE_META[value]?.label;
      },
    },
    {
      label: 'Number of Repayments',
      name: 'numberOfRepayments',
      element: TwNumberInput,
    },
    {
      label: 'Fee Type',
      name: 'feeSettings.feeType',
      element: SelectInput,
      options: feeTypeOptions,
      placeholder: '-- Select Fee Type --',
      required: true,
    },
    {
      label: 'Fee',
      name: 'feeSettings.feePct',
      element: PercentageNumberInput,
      required: true,
      isHidden: formValues.feeSettings?.feeType !== FEE_TYPE.FIXED,
    },
    {
      label: 'IRR Cap',
      name: 'feeSettings.irrCapPct',
      element: PercentageNumberInput,
      isHidden: formValues.feeSettings?.feeType !== FEE_TYPE.IRR_CAP,
    },
    {
      label: 'Terms',
      name: 'repaymentTerms',
      element: Input,
    },
  ];

  if (dealType === DEAL_TYPE.ROAS) {
    fields = fields.concat(
      {
        label: 'Revenue Margin',
        name: 'pctFromRevenueShare',
        element: PercentageNumberInput,
        required: dealType === DEAL_TYPE.ROAS,
      },
      {
        label: 'Revenue Type',
        name: 'revenueTotalType',
        element: SelectInput,
        required: dealType === DEAL_TYPE.ROAS,
        placeholder: 'Select Revenue Type',
        options: generateSelectOptions({ metaObj: TOTAL_TYPE_META }),
      },
      {
        label: 'True Up',
        name: 'isTrueUp',
        element: Checkbox,
        required: dealType === DEAL_TYPE.ROAS,
      }
    );
  }
  return fields;
};

const Deal = () => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, reset, control, watch, setValue } = useForm();

  const toast = useToast();
  const navigate = useNavigate();
  const formValues = watch();
  const { repaymentFrequency, dealType } = formValues;

  const {
    repaymentDaysOptions,
    daysBeforeEndOfMonthOptions,
    onRepaymentFrequencyFieldChange,
  } = useRepaymentSchedule({ repaymentFrequency });

  const { feeTypeOptions } = useFeeTypeOptions({ dealType });

  const { customerId, dealId } = useParams();

  const isPageDisabled = !!dealId;

  useEffect(() => {
    const init = async () => {
      if (dealId) {
        const deal = await getDeal({ customerId, dealId });
        reset(deal);
      } else {
        reset({
          daysBeforeEndOfMonth: 0,
        });
      }
    };

    init();
  }, []);

  useEffect(() => {
    const values = cloneDeep(formValues);
    if (dealType === DEAL_TYPE.ROAS) {
      values.pctFromRevenueShare = undefined;
      values.revenueTotalType = undefined;
      values.isTrueUp = false;
    } else if (dealType === DEAL_TYPE.FIXED) {
      delete values.pctFromRevenueShare;
      delete values.revenueTotalType;
      delete values.isTrueUp;
    }

    if (values.feeSettings) {
      values.feeSettings.feeType = undefined;
    }

    reset(values);
  }, [dealType]);

  const fields = useMemo(() => {
    return createFields({
      dealType: formValues.dealType,
      formValues,
      repaymentDaysOptions,
      daysBeforeEndOfMonthOptions,
      feeTypeOptions,
    });
  });

  const getDeal = async () => {
    try {
      setLoading(true);
      const deal = await getDealApi({ customerId, dealId });
      return deal;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load deal',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const saveDeal = async (data) => {
    try {
      setLoading(true);
      await createDealApi({ customerId, data });
      toast({
        title: 'Success',
        description: 'Deal updated successfully',
        status: 'success',
      });
      navigate(`/operations/${customerId}/deal`);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create deal',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    saveDeal(data);
  };

  const onFieldChange = (name, value) => {
    onRepaymentFrequencyFieldChange({
      repaymentFrequency,
      setValue,
      name,
      value,
    });
    setValue(name, value);
  };

  function isFormFilled() {
    const values = cloneDeep(formValues);

    return !isObjectEmpty(formValues) && hasObjectAllValues(values);
  }

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box minW={1087} maxW={'max'} fontSize={14} mt={-10}>
      <BackLink
        text={'Back to deals table'}
        path={`/operations/${customerId}/deal`}
      />
      <Card mt={'100px'}>
        <Flex align="baseline" gap={10}>
          <Heading
            variant={'sectionHeader'}
            borderColor={'twelve.green.300'}
            mb={10}
          >
            Deal Setup
          </Heading>
        </Flex>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <SimpleGrid
              w={'max'}
              gridTemplateColumns={'min-content min-content'}
              whiteSpace={'nowrap'}
              justifyContent={'space-between'}
              spacingY={4}
              spacingX={10}
              alignItems={'center'}
              fontSize={'sm'}
            >
              {fields.map((item, index) =>
                item.isHidden ? null : (
                  <Fragment key={index}>
                    <Text>{item.label}</Text>
                    <Box w={200}>
                      <Controller
                        control={control}
                        name={item.name}
                        render={renderInputElement({
                          item,
                          styleProps: { fontWeight: 800, w: 200 },
                          showPrefix: true,
                          isDisabled: isPageDisabled,
                          onChange: (name, value) => {
                            onFieldChange(name, value);
                          },
                        })}
                      />
                    </Box>
                  </Fragment>
                )
              )}
            </SimpleGrid>
            <Flex justify={'end'} mt={10}>
              <Button
                type={'submit'}
                variant={'brand'}
                w={200}
                isDisabled={isPageDisabled || !isFormFilled()}
              >
                Apply
              </Button>
            </Flex>
          </FormControl>
        </form>
      </Card>
    </Box>
  );
};

Deal.propTypes = {};

export default Deal;
