import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { Currency, Percentage } from 'components/Number';
import { DEAL_TYPE } from 'constants/dealConstants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useLayoutEffect, useRef } from 'react';
import { transformAmortizationData } from 'transformers/amortizationTransformer';

const AmortizationTableCell = ({ children, tooltip }) => (
  <SimpleGrid
    columns={2}
    gridTemplateColumns={'min-content min-content'}
    whiteSpace={'nowrap'}
  >
    {tooltip && (
      <>
        <Box></Box>
        <Tooltip label={tooltip} minW={'max'}>
          <Flex justify={'end'}>
            <Flex
              gap={1}
              mt={-1}
              mr={-1}
              mb={1}
              fontSize={'sm'}
              alignItems={'center'}
            >
              <Text>
                <u>more info</u>
              </Text>
              <QuestionIcon />
            </Flex>
          </Flex>
        </Tooltip>
      </>
    )}
    {children}
  </SimpleGrid>
);

const AmortizationTableCellRow = ({ label, value, underline }) => (
  <>
    <Box
      p={1}
      borderColor={'secondaryGray.900'}
      borderBottomWidth={underline ? 1 : 0}
    >
      <Text>{label}: </Text>
    </Box>
    <Box
      p={1}
      borderColor={'secondaryGray.900'}
      borderBottomWidth={underline ? 1 : 0}
    >
      {value !== null ? (
        <Currency value={value} maximumFractionDigits={2} />
      ) : (
        <Text>N/A</Text>
      )}
    </Box>
  </>
);

const AmortizationTableCellTooltip = ({
  installment,
  amortizationItem,
  dealType,
}) => {
  const {
    revenueStartDate,
    revenueEndDate,
    repayments,
    actualPaidAmount,
    revenueSharePct,
  } = amortizationItem;
  const { fee, revenueType, revenueMarginPct } = installment;

  return (
    <SimpleGrid
      columns={2}
      gap={1}
      gridTemplateColumns={'min-content min-content'}
      whiteSpace={'nowrap'}
      p={2}
    >
      {revenueStartDate && revenueEndDate && (
        <>
          <Text>Cohort Activity: </Text>
          <Text>{`${moment(revenueStartDate).format('MM/DD/YYYY')} - ${moment(
            revenueEndDate
          ).format('MM/DD/YYYY')}`}</Text>
        </>
      )}
      <>
        <Text>Fee: </Text>
        <Percentage value={fee} />
      </>
      {revenueSharePct && (
        <>
          <Text>Revenue Share: </Text>
          <Percentage value={revenueSharePct} />
        </>
      )}
      {revenueType && (
        <>
          <Text>Revenue Type: </Text>
          <Text>{revenueType}</Text>
        </>
      )}
      {revenueMarginPct && (
        <>
          <Text>Revenue Margin: </Text>
          <Percentage value={revenueMarginPct} />
        </>
      )}
      {repayments && repayments.length > 0 && (
        <>
          <Text fontWeight={800} textDecoration={'underline'} mt={1}>
            Repayments
          </Text>
          <Box></Box>
          {repayments.map((repayment, index) => (
            <Fragment key={`tooltipRepayment${index}`}>
              <Flex gap={1}>
                <Text>{moment(repayment.date).format('MM/DD/YYYY')}</Text>
                <Currency value={repayment.amount} maximumFractionDigits={2} />
              </Flex>
              <Text>{repayment.comment}</Text>
            </Fragment>
          ))}
        </>
      )}
      {actualPaidAmount && (
        <>
          <Text>Minimum: </Text>
          <Currency
            value={amortizationItem.minAmount}
            maximumFractionDigits={2}
          />
          {dealType === DEAL_TYPE.ROAS && (
            <>
              <Text>Predicted Revenue Share: </Text>
              {amortizationItem.revenueSharePredictedAmount ? (
                <Currency
                  value={amortizationItem.revenueSharePredictedAmount}
                  maximumFractionDigits={2}
                />
              ) : (
                <Text>N/A</Text>
              )}
              <Text>Actual Revenue Share: </Text>
              {amortizationItem.revenueShareAmount ? (
                <Currency
                  value={amortizationItem.revenueShareAmount}
                  maximumFractionDigits={2}
                />
              ) : (
                <Text>N/A</Text>
              )}
            </>
          )}
          <Text>Expected Repayment: </Text>
          {amortizationItem.amountToPay ? (
            <Currency
              value={amortizationItem.amountToPay}
              maximumFractionDigits={2}
            />
          ) : (
            <Text>N/A</Text>
          )}
        </>
      )}
    </SimpleGrid>
  );
};

const AmortizationCellItemContent = ({ row, item }) => {
  const dealType = row.dealType;

  return (
    <AmortizationTableCell
      tooltip={
        <AmortizationTableCellTooltip
          installment={row}
          amortizationItem={item}
          dealType={dealType}
        />
      }
    >
      {item.actualPaidAmount ? (
        <AmortizationTableCellRow
          label={'Actual Repayment'}
          value={item.actualPaidAmount}
        />
      ) : (
        <>
          <AmortizationTableCellRow label={'Minimum'} value={item.minAmount} />
          {dealType === DEAL_TYPE.ROAS && (
            <>
              <AmortizationTableCellRow
                label={'Predicted Revenue Share'}
                value={item.revenueSharePredictedAmount}
              />
              <AmortizationTableCellRow
                label={'Actual Revenue Share'}
                value={item.revenueShareAmount}
                underline={true}
              />
            </>
          )}
          <AmortizationTableCellRow
            label={'Expected Repayment'}
            value={item.amountToPay}
            underline={true}
          />
          <AmortizationTableCellRow
            label={'Actual Repayment'}
            value={item.actualPaidAmount}
          />
        </>
      )}
    </AmortizationTableCell>
  );
};

const AmortizationTable = ({ data, isPredicted }) => {
  const tableRef = useRef(null);
  const wrapperRef = useRef(null);
  const transformedData = transformAmortizationData({ data, isPredicted });

  const nextRepaymentDateIndex = transformedData.dates.findIndex((date) => {
    const today = moment();
    return (
      moment(date).isAfter(today) ||
      moment(date).format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
    );
  });

  const nextRepaymentCellBgColor = 'twelve.green.200';
  const stickyColumnWidth = 130;

  useLayoutEffect(() => {
    const tds = tableRef.current.getElementsByTagName('td');

    if (!tds.length) {
      return;
    }

    const nextRepaymentTd = tds[nextRepaymentDateIndex + 3]; // 3 is the number of the first 3 columns that are not dates
    const rect = nextRepaymentTd.getBoundingClientRect();

    wrapperRef.current.scrollTo({
      left: rect.left - rect.width - stickyColumnWidth * 4,
    });
  }, [data]);

  return (
    <Box p={4}>
      <TableContainer
        maxH={'calc(100vh - 200px)'}
        overflowY={'auto'}
        ref={wrapperRef}
      >
        <Table
          ref={tableRef}
          variant={'noPadding'}
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0',
          }}
          w={'max'}
          __css={{
            'td, th': {
              padding: '10px',
              borderRight: '1px solid',
              borderBottom: '1px solid',
              background: 'white',
            },

            th: {
              borderTop: '1px solid',
              position: 'sticky',
              top: 0,
              zIndex: 2,
            },

            'td.next-repayment, th.next-repayment': {
              background: nextRepaymentCellBgColor,
            },

            'td:first-child, th:first-child': {
              borderLeft: '1px solid',
            },

            'td.borderRight, th.borderRight': {
              borderRight: '4px solid',
            },

            '.sticky-x': {
              width: stickyColumnWidth,
              position: 'sticky',
              left: 0,
              zIndex: 1,
              whiteSpace: 'break-spaces',
            },

            'th.sticky-x': {
              zIndex: 3,
              left: 0,
            },

            '.sticky-x:nth-child(2)': {
              left: stickyColumnWidth,
            },

            '.sticky-x:nth-child(3)': {
              left: stickyColumnWidth * 2,
            },

            '.sticky-x:nth-child(4)': {
              left: stickyColumnWidth * 3,
            },
          }}
        >
          <Thead>
            <Tr>
              <Th className="sticky-x">Cohort Dates</Th>
              <Th className="sticky-x">Transfer Date</Th>
              <Th className="sticky-x">Purchase Price</Th>
              <Th className={'borderRight sticky-x'}>Amount Sold</Th>
              {transformedData.dates.map((date, index) => (
                <Th
                  key={date}
                  className={
                    index === nextRepaymentDateIndex && 'next-repayment'
                  }
                >
                  {moment(date).format('MM/DD/YY')}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {transformedData.data.map((row, index) => (
              <Tr key={index}>
                <Td className="sticky-x">
                  {row.cohortStartDate && row.cohortEndDate
                    ? `${moment(row.cohortStartDate).format(
                        'MM/DD/YY'
                      )} - ${moment(row.cohortEndDate).format('MM/DD/YY')}`
                    : 'N/A'}
                </Td>
                <Td className="sticky-x">
                  {moment(row.transferDate).format('MM/DD/YY')}
                </Td>
                <Td className="sticky-x">
                  <Currency
                    value={row.purchasePrice}
                    maximumFractionDigits={2}
                  />
                </Td>
                <Td className={'borderRight sticky-x'}>
                  <Currency value={row.amountSold} maximumFractionDigits={2} />
                </Td>
                {row.amortization.map((item, index) => {
                  return (
                    <Td
                      key={index}
                      className={
                        index === nextRepaymentDateIndex && 'next-repayment'
                      }
                    >
                      {item ? (
                        <AmortizationCellItemContent
                          row={row}
                          item={item}
                        ></AmortizationCellItemContent>
                      ) : (
                        '-'
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
            <Tr fontWeight={800} m={10}>
              <Td className="sticky-x"></Td>
              <Td className="sticky-x">Total</Td>
              <Td className="sticky-x">
                <Currency
                  value={transformedData.totals.purchasePrice}
                  maximumFractionDigits={2}
                />
              </Td>
              <Td className={'sticky-x borderRight'}>
                <Currency
                  value={transformedData.totals.amountSold}
                  maximumFractionDigits={2}
                />
              </Td>
              {transformedData.totals.amortization.map((total, index) => (
                <Td
                  key={index}
                  className={
                    index === nextRepaymentDateIndex && 'next-repayment'
                  }
                >
                  <AmortizationTableCell>
                    <AmortizationTableCellRow
                      label={'Excepted Repayment'}
                      value={total.amountToPay}
                    />
                    <AmortizationTableCellRow
                      label={'Actual Repayment'}
                      value={total.actualPaidAmount}
                    />
                    <AmortizationTableCellRow
                      label={'Remaining'}
                      value={total.leftToPay}
                    />
                  </AmortizationTableCell>
                  <Currency value={total.amount} maximumFractionDigits={2} />
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

AmortizationTable.propTypes = {
  data: PropTypes.array,
  isPredicted: PropTypes.bool,
};

export default AmortizationTable;
