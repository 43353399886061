import { DEAL_TYPE, FEE_TYPE, FEE_TYPE_META } from 'constants/dealConstants';
import { generateSelectOptions } from 'helpers/inputHelper';
import { useEffect, useState } from 'react';

export function useFeeTypeOptions({ dealType }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(createFeeTypeOptions({ dealType }));
  }, [dealType]);

  const createFeeTypeOptions = ({ dealType }) => {
    if (!dealType) {
      return [];
    }

    let _options = generateSelectOptions({ metaObj: FEE_TYPE_META });

    if (dealType === DEAL_TYPE.FIXED) {
      _options = _options.filter((option) => option.value === FEE_TYPE.FIXED);
    }

    return _options;
  };

  return { feeTypeOptions: options };
}
